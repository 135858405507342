import { FC, useEffect, useRef, useState } from 'react'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import useStats, { UseStatsProps } from '@lib/hooks/useStats'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { isMobile } from 'react-device-detect'

import {
  HitsSearchBar,
  HitsSuggestions,
} from '@components/common/Header/Search/Algolia/Hits'

import SearchBox from '@components/common/Header/Search/Algolia/SearchBox'
import { StatsResultsSeeMore } from '@components/common/Header/Search/Algolia/Stats'
import NoResults from '@components/common/Header/Search/Algolia/Stats/NoResults'
import {
  analyticsTagsAlgolia,
  filterExcludedCountries,
  FROM,
  getRecentSearchesFromLocalStorage,
  indexNameSuggestion,
  userTokenAlgolia,
} from '@lib/lib-algolia/algolia'
import { Configure, Index } from 'react-instantsearch'

import cn from 'classnames'
import s from './SearchBarAlgolia.module.css'
import MediaQueries from '@components/common/MediaQueries'
import { Cross } from '@components/icons'

import { changeLocaleIfTranslatable } from '@utils/locale'

import { stringify } from 'query-string'

export type SearchBarAlgolia = SearchBarAlgoliaProps & UseStatsProps

const SearchBarAlgolia: FC<SearchBarAlgolia> = (props) => {
  const { open, onClose, clearInput } = props
  const { t } = useTranslation()
  const { deliveryCountryCode, uid } = useUserSettings()
  const router = useRouter()
  const { locale } = useRouter()
  const { closeModal } = useUI()

  const applicationIndex =
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_INDEX || 'preprod_'

  const { nbHits, query, processingTimeMS } = useStats()

  const screen = useScreen()

  const inputSearch = useRef<HTMLInputElement>(null)
  const formSearch = useRef<HTMLFormElement>(null)

  const focusInputSearch = () => {
    formSearch.current.reset()
    inputSearch.current.focus()
  }

  const [withSuggestion, setWithSuggestion] = useState(false)
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState(null)
  const [resultView, setResultView] = useState('neutral')
  const [recentSearches, setRecentSearches] = useState(null)

  const handleDeleteRecentSearch = (index) => {
    const newRecentSearches = recentSearches.filter((_, i) => i !== index)
    setRecentSearches(newRecentSearches)
  }
  const handleDeleteAllRecentSearches = () => {
    setRecentSearches([])
  }

  const handleGoToRecentSearch = (recentQuery) => {
    closeModal()
    if (recentQuery?.isBrand) {
      router.push(
        `/${t('block:brand.brandsUrl')}/${recentQuery.brandSlug}`,
        undefined,
        {
          locale: changeLocaleIfTranslatable(locale),
        }
      )
      return
    }
    if (router.asPath.includes('/search')) {
      window.location.href = `/${locale}/search/?${stringify({
        q: recentQuery.query,
      })}`
    } else {
      router.push(
        `/search/?${stringify({
          q: recentQuery.query,
        })}`,
        undefined,
        {
          locale: changeLocaleIfTranslatable(locale),
        }
      )
    }
  }

  useEffect(() => {
    const recentSearchFromLocalStorage = getRecentSearchesFromLocalStorage()
    if (recentSearchFromLocalStorage)
      setRecentSearches(recentSearchFromLocalStorage)
  }, [])

  useEffect(() => {
    if (recentSearches?.length === 0) {
      localStorage.removeItem('ALGOLIA_RECENT_SEARCHES')
    } else {
      localStorage.setItem(
        'ALGOLIA_RECENT_SEARCHES',
        JSON.stringify(recentSearches)
      )
    }
  }, [recentSearches])

  useEffect(() => {
    if (processingTimeMS === 0) return
    if (!query || query.length < 1 || inputSearch?.current?.value.length < 1) {
      setResultView('neutral')
    } else if (nbHits < 1) {
      setResultView('noResult')
    } else if (nbHits > 0) {
      setResultView('result')
    }
  }, [nbHits, query])

  return (
    <div
      className={cn(
        s.container,
        { [s.deploy]: resultView === 'result' || recentSearches?.length > 0 },
        { [s.withSuggestion]: withSuggestion }
      )}
    >
      <div className={s.content}>
        <div className={s.searchBox}>
          <div className={s.searchBoxForm}>
            <SearchBox
              autoFocus={true}
              inputRef={inputSearch}
              formRef={formSearch}
              placeholder={
                screen && [Screen.xs].includes(screen)
                  ? t('layout:header.search.ariaSearch')
                  : t('layout:header.search.placeHolderSeach')
              }
              recentSearches={recentSearches}
              open={open}
              onClose={onClose}
              autocompleteSuggestions={autocompleteSuggestions}
              resultView={resultView}
              clearInput={clearInput}
            />
          </div>
        </div>

        <div className={s.resultsContainer}>
          {recentSearches?.length > 0 && resultView !== 'result' && (
            <div className={cn(s.recentSuggestion)}>
              <div className={s.recentSearchTitleBlock}>
                <div className={s.recentSearchTitle}>
                  {' '}
                  {t('layout:header.search.recentQueries')}
                </div>
                <Cross
                  className={s.deleteAllCross}
                  onClick={handleDeleteAllRecentSearches}
                />
              </div>
              <ul>
                {recentSearches.map((search, index) => (
                  <li className={s.hit} key={index}>
                    <div
                      className={cn(s.link)}
                      onClick={() => handleGoToRecentSearch(search)}
                    >
                      {search?.isBrand ? (
                        <div className={s.brandLink}>
                          {' '}
                          {search?.query}{' '}
                          <div className={s.brandTag}>
                            {' '}
                            {t(
                              'layout:header.search.designer'
                            ).toUpperCase()}{' '}
                          </div>
                        </div>
                      ) : (
                        <div>{search.query}</div>
                      )}
                    </div>
                    <Cross
                      className={s.deleteCross}
                      onClick={() => {
                        handleDeleteRecentSearch(index)
                      }}
                    />
                  </li>
                ))}
              </ul>
              {recentSearches?.length > 1 && (
                <div
                  className={s.deleteAllText}
                  onClick={handleDeleteAllRecentSearches}
                >
                  {t('layout:header.search.clearAll')}
                </div>
              )}
            </div>
          )}
          <div
            className={cn(s.box, s.box_left, {
              ['hidden']: resultView !== 'result',
            })}
          >
            <div className={s.leftPanel}>
              <Index indexName={`${applicationIndex}brands`}>
                <Configure
                  analyticsTags={analyticsTagsAlgolia({
                    deliveryCountryCode,
                    locale,
                    isMobile,
                    uid,
                    from: FROM.SEARCH_BAR,
                  })}
                  clickAnalytics
                  filters={filterExcludedCountries(deliveryCountryCode)}
                  hitsPerPage={6}
                  userToken={userTokenAlgolia()}
                />
                <div className={cn({ ['hidden']: resultView !== 'result' })}>
                  <HitsSuggestions isBrand={true} />
                </div>
              </Index>
              <Index
                indexName={indexNameSuggestion(deliveryCountryCode, locale)}
              >
                <Configure
                  analyticsTags={analyticsTagsAlgolia({
                    deliveryCountryCode,
                    locale,
                    isMobile,
                    uid,
                    from: FROM.SEARCH_BAR,
                  })}
                  clickAnalytics
                  filters={filterExcludedCountries(deliveryCountryCode)}
                  hitsPerPage={6}
                  userToken={userTokenAlgolia()}
                />
                <div className={cn({ ['hidden']: resultView !== 'result' })}>
                  <HitsSuggestions
                    setWithSuggestion={setWithSuggestion}
                    setSuggestion={setAutocompleteSuggestions}
                    inputValue={inputSearch.current?.value}
                  />
                </div>
              </Index>
            </div>
          </div>
          <MediaQueries hidden={['xs', 'sm', 'md']}>
            <div
              className={cn(s.full_width, {
                [s.box]: resultView === 'result',
              })}
            >
              <div
                className={cn(s.results, {
                  ['hidden']: resultView !== 'result',
                })}
              >
                <div className={s.rightPanel}>
                  <HitsSearchBar />
                </div>
              </div>
              {resultView === 'noResult' && (
                <div className={s.noResult}>
                  <NoResults
                    query={query}
                    focusInputSearch={focusInputSearch}
                    layout="modal"
                  />
                </div>
              )}

              <div
                className={cn(s.seeMore, {
                  ['hidden']: resultView !== 'result',
                })}
              >
                <StatsResultsSeeMore />
              </div>
            </div>
          </MediaQueries>
        </div>
      </div>
    </div>
  )
}

export default SearchBarAlgolia
