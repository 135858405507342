import { FC } from 'react'
import { Link } from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { useUserSettings } from '@context/session'
import { getItemsName } from '@utils/basket/items'
import { cardPriceFormat, isDiffPrice } from '@utils/prices'
import productUrl from '@utils/url/product'

import { tagAlgoliaClick, getApiPrices, FROM } from '@lib/lib-algolia/algolia'
import { mediaSrcProduct } from '@utils/algolia'
import { Highlight } from 'react-instantsearch'

import s from './ProductSearchCard.module.css'

const ProductSearchCard: FC<ProductSearchCardProps> = ({ hit }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { currencyCode, currencyRate, deliveryCountryCode } = useUserSettings()

  const { attachColor, brand, color, id, name, siblings } = hit

  const productName = getItemsName({ attachColor, name, color })

  let medias = mediaSrcProduct(hit, ['648x648q80'])

  const prices = getApiPrices({
    hit,
    currencyCode,
    currencyRate,
    deliveryCountryCode,
    locale,
  })
  const pricesFormatted = cardPriceFormat(prices, locale) as DeclinationPrice
  const diffPrice = isDiffPrice(prices)

  const urlProduct = productUrl({
    attachColor,
    brand,
    color,
    id,
    name,
    locale,
  })

  return (
    <div className={s.container}>
      <Link
        href={`${urlProduct}${
          hit.__queryID ? `?algsearch=${hit.__queryID}` : ''
        }`}
        aria-label={`${t('layout:common.ariaNavigate')} ${name}`}
        onClick={(e) => {
          tagAlgoliaClick({
            hit,
            deliveryCountryCode,
            locale,
            from: FROM.SEARCH_BAR,
          })
        }}
      >
        <div className={s.content}>
          <div className={s.img}>
            <div className={s.productImage}>
              <Image
                src={
                  medias['648x648q80']
                    ? `https:${medias['648x648q80']}`
                    : '/img/apple-touch-icon.png'
                }
                alt={productName}
                aria-label={productName}
                layout="responsive"
                width="95"
                height="95"
                unoptimized
              />
            </div>
          </div>
          <ul className={s.attr}>
            <li className={s.brand} title={brand?.name}>
              <strong>{brand?.name}</strong>
            </li>
            <li
              className={s.title}
              title={productName}
              aria-label={productName}
            >
              <Highlight attribute="name" hit={hit} />
              {attachColor && color && (
                <>
                  &nbsp;|&nbsp;
                  <Highlight attribute="colorGroup" hit={hit} />
                </>
              )}
            </li>
            <li className={s.prices}>
              {pricesFormatted && (
                <>
                  {diffPrice && (
                    <span className={s.labelFrom}>
                      {t('product:price.from')}&nbsp;
                    </span>
                  )}
                  {pricesFormatted.baseAmount &&
                  pricesFormatted.baseAmount !== pricesFormatted.amount ? (
                    <>
                      <span className={s.salePrice}>
                        {pricesFormatted.baseAmount}
                      </span>
                      <span className={s.initialPrice}>
                        {pricesFormatted.amount}
                      </span>
                      <span
                        className={s.discountAmount}
                      >{`-${pricesFormatted.discount}`}</span>
                    </>
                  ) : (
                    pricesFormatted.amount
                  )}
                </>
              )}
            </li>
            {siblings?.length > 1 ? (
              <>
                <li className={s.siblings}>
                  {t('product:siblingSelector.colors', {
                    nbr: siblings.length + 1,
                  })}
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </Link>
    </div>
  )
}

export default ProductSearchCard
