import React from 'react'

import ProductSearchCard from '@components/product/ProductSearchCardLine'

import { useHits, UseHitsProps } from 'react-instantsearch'

import s from './HitsSearchBar.module.css'

export function HitsSearchBar(props: UseHitsProps) {
  const { hits } = useHits(props)

  return (
    <>
      {hits && (
        <div className={s.container}>
          {hits.map((hit) => (
            <div className={s.hit} key={hit.objectID}>
              <ProductSearchCard hit={hit} />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default HitsSearchBar
