import { useEffect } from 'react'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import router, { useRouter } from 'next/router'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import LinkBrand from '../../SearchModal/SearchBarAlgolia/LinkBrand'

import { indexNameAlgolia } from '@lib/lib-algolia'
import { changeLocaleIfTranslatable } from '@utils/locale'
import {
  itemToBrand,
  setRecentSearchesToLocalStorage,
} from '@lib/lib-algolia/algolia'
import { Highlight, useHits, UseHitsProps } from 'react-instantsearch'

import cn from 'classnames'
import s from './HitsSuggestions.module.css'
import { SearchIconLight } from '@components/icons/Search'

export interface SuggestionProps {
  setWithSuggestion?: (withSuggestion: boolean) => void
  setSuggestion?: (suggestion: string[]) => void
  inputValue?: string
  isBrand?: boolean
}

export function HitsSuggestions(
  props: ComponentAlgoliaProps & UseHitsProps & SuggestionProps
) {
  const { closeModal } = useUI()
  const { locale } = useRouter()
  const { deliveryCountryCode } = useUserSettings()
  const screen = useScreen()
  const indexAlgolia = indexNameAlgolia(deliveryCountryCode, locale)

  const {
    title,
    uppercase,
    setWithSuggestion,
    setSuggestion,
    inputValue,
    isBrand = false,
    ...restProps
  } = props

  const { hits } = useHits(restProps)

  const suggestion = (hit) => {
    let name = hit.query

    if (!isBrand) {
      const nameAndSlug = (hit[indexAlgolia] as any)?.facets?.exact_matches[
        'brand.nameAndSlug'
      ]
      let brandJson = nameAndSlug ? nameAndSlug[0].value : null
      const brand = !!brandJson ? itemToBrand(brandJson) : null
      if (
        brand?.name.toLowerCase() === name ||
        brand?.slug.toLowerCase() === name
      )
        return null
    }

    return (
      <li className={s.hit} key={hit.objectID}>
        <div className={s.loupe}>
          <SearchIconLight
            className={s.icon}
            size={
              [Screen.xs, Screen.sm, Screen.md].includes(screen) ? '24' : '14'
            }
          />
        </div>
        {isBrand ? (
          <LinkBrand
            className={s.link}
            brand={{ name, slug: hit?.slug }}
            hit={hit}
          />
        ) : (
          <button
            className={s.link}
            onClick={() => {
              setRecentSearchesToLocalStorage(hit.query)
              closeModal()
              router.push(`/search?q=${hit.query}`, undefined, {
                locale: changeLocaleIfTranslatable(router.locale),
              })
            }}
          >
            <Highlight attribute="query" hit={hit} />
          </button>
        )}
      </li>
    )
  }

  useEffect(() => {
    if (!setWithSuggestion) return
    setWithSuggestion(hits.length > 0)
    const autocompleteSuggestion: string[] = hits?.map(
      (hit) => hit?.query as string
    )
    setSuggestion(autocompleteSuggestion)
  }, [hits])

  return (
    <div className={s.suggestions}>
      {hits && hits.length > 0 && (
        <div className={cn(s.suggestion, { [s.noMarginTop]: !isBrand })}>
          {title && (
            <div className={cn(s.boxTitle, s.boxTitleBrand)}>
              <p className={cn(s.title, { [s.text_uppercase]: uppercase })}>
                {title}
              </p>
            </div>
          )}

          <ul
            className={cn(s.container, {
              [s.noPaddingTop]: !isBrand,
              [s.noPaddingBottom]: isBrand,
            })}
          >
            {hits.map((hit) => suggestion(hit))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default HitsSuggestions
