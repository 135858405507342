import React, { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@components/ui'
import { setRecentSearchesToLocalStorage } from '@lib/lib-algolia'
import { Highlight } from 'react-instantsearch'

import s from './Link.module.css'
import cn from 'classnames'

const LinkBrand: FC<LinkBrandProps> = ({ brand, className, hit }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const handleOnClick = (brand) => {
    setRecentSearchesToLocalStorage(brand.name, true, brand.slug)
  }

  return (
    <Link
      className={cn(s.link, s.link_underline, className)}
      aria-label={`${t('layout:common.ariaNavigate')} brands ${brand.name}`}
      href={`/${locale}/${t('block:brand.brandsUrl')}/${brand.slug}`}
      onClick={() => handleOnClick(brand)}
    >
      <Highlight attribute="query" hit={hit} />
      <div className={s.brandTag}>
        {' '}
        {t('layout:header.search.designer').toUpperCase()}{' '}
      </div>
    </Link>
  )
}

export default LinkBrand
