import { useUI } from '@components/ui/context'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import useStats, { UseStatsProps } from '@lib/hooks/useStats'
import { changeLocaleIfTranslatable } from '@utils/locale'

import cn from 'classnames'
import s from './StatsResults.module.css'

const StatsResultsSeeMore = (props: UseStatsProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { closeModal } = useUI()
  const { nbHits, query } = useStats()

  const hasResults = (nbHits !== 0 && !!query) || false

  return (
    <div className={cn({ [s.seeMore]: hasResults })} hidden={!hasResults}>
      <button
        className={s.btnSeeMore}
        onClick={() => {
          closeModal()
          if (router.asPath.includes('/search')) {
            window.location.href = `/${router.locale}/search/?q=${query}`
          } else {
            router.push(`/search/?q=${query}`, undefined, {
              locale: changeLocaleIfTranslatable(router.locale),
            })
          }
        }}
      >
        {t('layout:header.search.viewMore')} ({nbHits})
      </button>
    </div>
  )
}

export default StatsResultsSeeMore
