import { useEffect } from 'react'
import { useQueryRules } from 'react-instantsearch-core'

export default function RedirectAlgolia() {
  const { items } = useQueryRules()

  useEffect(() => {
    const match = items?.find((data) => Boolean(data.redirect)) || null
    if (match && match.redirect) {
      window.location.href = match.redirect
    }
  }, [items])

  return null
}
