import React, { FC, useEffect, useRef } from 'react'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import { useRouter } from 'next/router'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import {
  analyticsTagsAlgolia,
  filterExcludedCountries,
  FROM,
  indexNameAlgolia,
  searchClient,
  userTokenAlgolia,
} from '@lib/lib-algolia/algolia'
import { Configure, InstantSearch } from 'react-instantsearch'
import RedirectAlgolia from '@components/common/Header/Search/SearchModal/RedirectAlgolia'
import SearchBarAlgolia from '@components/common/Header/Search/SearchModal/SearchBarAlgolia'
import { tagEventCS } from '@components/common/ContentSquare'

import { blockScroll, enableScroll } from '@utils/scrollLock'
import FocusLock from 'react-focus-lock'
import { isMobile, isMobileSafari } from 'react-device-detect'
import Div100vh from 'react-div-100vh'

import cn from 'classnames'
import s from './SearchModal.module.css'

export interface SearchModalDisplayProps {
  locale: string
  screen: Screen
  deliveryCountryCode: string
  uid: string
  open: boolean
  clearInput?: boolean
  closeModal: () => void
}

const SearchModalDisplay: FC<SearchModalDisplayProps> = ({
  locale,
  screen,
  deliveryCountryCode,
  uid,
  open,
  closeModal,
  clearInput = false,
}) => {
  const isMounted = useRef<boolean>(false)

  useEffect(() => {
    if (isMounted.current || open) {
      if (open) {
        blockScroll(false)
        tagEventCS({
          eventName: 'search_modal_open',
          eventType: 'trackPageEvent',
        })
      } else enableScroll(false)
    }
  }, [open])

  useEffect(() => {
    isMounted.current = true
  }, [])

  if (!open) return null

  return (
    <FocusLock disabled={!open}>
      <Div100vh className={cn(s.container, { [s.open]: open })}>
        <div
          className={cn(s.layout, { [s.disableAction]: isMobileSafari })}
          onClick={() => closeModal()}
          tabIndex={-1}
          aria-hidden
        />
        <div className={s.topBanner} />
        <div className={cn(s.content, s.animation)}>
          <InstantSearch
            indexName={indexNameAlgolia(deliveryCountryCode, locale)}
            searchClient={searchClient}
          >
            <Configure
              analyticsTags={analyticsTagsAlgolia({
                deliveryCountryCode,
                locale,
                isMobile,
                uid,
                from: FROM.SEARCH_BAR,
              })}
              clickAnalytics
              enablePersonalization={false}
              filters={filterExcludedCountries(deliveryCountryCode)}
              hitsPerPage={
                [Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(screen)
                  ? 6
                  : 5
              }
              userToken={userTokenAlgolia()}
            />
            <RedirectAlgolia />
            <SearchBarAlgolia
              id="searchBar-modal"
              open={open}
              onClose={() => closeModal()}
              clearInput={clearInput}
            />
          </InstantSearch>
        </div>
      </Div100vh>
    </FocusLock>
  )
}

const SearchModalDisplayMemo = React.memo(SearchModalDisplay)

export interface SearchModalProps {
  open?: boolean
  clearInput?: boolean
}

const SearchModal: FC<SearchModalProps> = ({ open, clearInput }) => {
  const { locale } = useRouter()
  const screen = useScreen()
  const { deliveryCountryCode, uid } = useUserSettings()
  const { closeModal } = useUI()

  return (
    <SearchModalDisplayMemo
      locale={locale}
      screen={screen}
      deliveryCountryCode={deliveryCountryCode}
      uid={uid}
      open={open}
      closeModal={closeModal}
      clearInput={clearInput}
    />
  )
}

export default SearchModal
